import Vue from 'vue'
import App from './App.vue'
import router from './router'
import BootstrapVue from 'bootstrap-vue'
import FlagIcon from 'vue-flag-icon'
import VuejsDialog from 'vuejs-dialog'
import Vuetify from 'vuetify'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vuejs-dialog/dist/vuejs-dialog.min.css'
import 'vuetify/dist/vuetify.min.css'

import { VueReCaptcha } from 'vue-recaptcha-v3'

Vue.use(BootstrapVue)
Vue.use(VuejsDialog)
Vue.use(FlagIcon)
Vue.use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
  loaderOptions: {
    useRecaptchaNet: true,
    autoHideBadge: true
  }
})
Vue.config.productionTip = false
Vue.use(Vuetify)

new Vue({
  router,
  Vuetify,
  render: h => h(App)
}).$mount('#app')
