<template>
    <div>
        <div class="container" style="min-height: 800px">
            <div class="row mt-5 border-0">
                <div class="col-lg-6 col-md-6 col-sm-12 mt-5 flex-grow-1">
                    <b-tabs content-class="mt-3"
                            active-nav-item-class="font-weight-bold text-dark"
                            active-tab-class=""
                            lazy>
                        <b-tab title="Info" active v-if="server">
                          <InfoTab :server="server"/>
                        </b-tab>
                        <b-tab title="Player stats" class="pt-4">
                            <div class="pl-3 pr-3" v-if="server.uuid">
                              <ToggleSwitch class="mb-5 d-flex justify-content-center" active
                                            v-model="playersPeriod" :group="'players'"
                                            :options="playersTogglerOptions"/>
                              <div class="">
                                <PlayerStatsTab :server="server.uuid"
                                                :period="playersPeriod.toLocaleLowerCase()"/>
                              </div>
                            </div>
                        </b-tab>
                        <b-tab title="Uptime stats" class="pt-4">
                            <div class="pl-3 pr-3" v-if="server.uuid">
                              <ToggleSwitch class="mb-5 d-flex justify-content-center" v-model="uptimePeriod"
                                            :group="'uptime'"
                                            :options="uptimeTogglerOptions"/>
                              <div class="">
                                <UptimeStatsTab class="" :server="server.uuid"
                                               :period="uptimePeriod.toLocaleLowerCase()"/>
                              </div>
                            </div>
                        </b-tab>
<!--                        <b-tab title="Banner">-->
<!--                            <div class="col-md-12" v-if="server.uuid">-->
<!--                                <div class="text-center">-->
<!--                                    <img class="mt-4 mb-4 adaptar-img" alt="promotional banner"-->
<!--                                         v-bind:src="apiService.getServerBannerSrc(server.uuid, 'sunset')">-->
<!--                                </div>-->
<!--                                <div class="">-->
<!--                                    <p class="font-weight-bolder mb-4 mt-4">HTML Code</p>-->
<!--                                    <div class="row d-flex justify-content-lg-around align-items-end border-0">-->
<!--                                        <textarea id="out" v-bind:value="this.generateBannerRaw" class="form-control"-->
<!--                                                  name="html" rows="3" cols="3" readonly></textarea>-->
<!--                                        <div class="col-12 pr-0 mt-2">-->
<!--                                            <b-button id="copy" v-if="supportsCB" @click="copy()" variant="dark"-->
<!--                                                      class="float-right shadow p-2 pl-3 pr-3 btn btn-sm">-->
<!--                                                <i class="fas fa-copy"></i>&nbsp;Copy-->
<!--                                            </b-button>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </b-tab>-->
                    </b-tabs>
                </div>

                <ServerContent :server="server" />
            </div>
        </div>
    </div>
</template>

<script>
    import {ApiService} from "@/components/ApiService";
    import ToggleSwitch from 'vuejs-toggle-switch'
    import PlayerStatsTab from "../components/server/tabs/PlayerStatsTab";
    import UptimeStatsTab from "../components/server/tabs/UptimeStatsTab";
    import {BTab, BTabs} from "bootstrap-vue"
    import InfoTab from "@/components/server/tabs/InfoTab";
    import ServerContent from "@/components/server/ServerContent";

    const apiService = new ApiService();

    export default {
        name: 'server',
        props: ['uuid'],
        components: {
            ServerContent,
            InfoTab,
            PlayerStatsTab,
            UptimeStatsTab,
            ToggleSwitch,
            BTab,
            BTabs,
        },
        data() {
            return {
                server: {tags: [], version: {}},
                apiService: apiService,
                playersPeriod: 'daily',
                playersTogglerDisabled: false,
                playersTogglerOptions: {
                    layout: {
                        color: 'black',
                        backgroundColor: '',
                        selectedColor: 'white',
                        selectedBackgroundColor: '#343a40',
                        fontFamily: 'Arial',
                        fontWeight: 'bold',
                        fontWeightSelected: 'bold',
                        squareCorners: false,
                        noBorder: false
                    },
                    size: {
                        width: 20,
                        height: 3,
                        padding: 0.5,
                        fontSize: 1.2,
                    },
                    items: {
                        delay: .2,
                        preSelected: 'Daily',
                        disabled: false,
                        labels: [
                            {name: 'Monthly'},
                            {name: 'Daily'},
                            {name: 'Hourly'},
                        ]
                    }
                },
                uptimePeriod: 'daily',
                uptimeTogglerDisabled: false,
                uptimeTogglerOptions: {
                    layout: {
                        color: 'black',
                        backgroundColor: '',
                        selectedColor: 'white',
                        selectedBackgroundColor: '#343a40',
                        fontFamily: 'Arial',
                        fontWeight: 'bold',
                        textTransform: 'uppercase',
                        fontWeightSelected: 'bold',
                        squareCorners: false,
                        noBorder: false
                    },
                    size: {
                        width: 20,
                        height: 3,
                        padding: 0.5,
                        fontSize: 1.2,
                    },
                    items: {
                        delay: .2,
                        preSelected: 'Daily',
                        disabled: false,
                        labels: [
                            {name: 'Monthly'},
                            {name: 'Daily'},
                        ]
                    }
                },
                supportsCB: false,
            }
        },
        created() {
            if (navigator.clipboard) {
                this.supportsCB = true;
            }
        },
        mounted() {
            this.showOrHideBadge(true);
            this.getServer(this.uuid);
        },
        beforeDestroy() {
            this.showOrHideBadge(false);
        },
        computed: {
            // generateBannerRaw: function () {
            //     return `<a href="` + window.location.origin + this.$route.fullPath + `" target="_blank"><img src="` + apiService.getServerBannerSrc(this.server.uuid, 'sunset') + `" alt="` + this.server.name + `" width="100%"/></a>`;
            // },
        },
        methods: {
            async showOrHideBadge(show) {
                await this.$recaptchaLoaded();
                if (show) {
                    this.$recaptchaInstance.showBadge();
                } else {
                    this.$recaptchaInstance.hideBadge();

                }
            },
            getServer(uuid) {
                apiService.getServer(uuid)
                    .then(response => {
                        this.server = response.data;
                    });
            },
            async vote(uuid) {
                await this.$recaptchaLoaded();
                let token = await this.$recaptcha('vote');
                apiService.voteServer(uuid, token).then(response => {
                    switch (response.data.status) {
                        case 200:
                        case 400:
                            this.$dialog.alert(response.data.message);
                            break;
                        default:
                            this.$dialog.alert('Oops, an error has occurred. Please try again.');
                            break;
                    }
                }).catch(() => {
                    this.$dialog.alert('Oops, an error has occurred. Please try again.');
                })
            },
            makeToast(variant = null, text, server) {
                this.$bvToast.toast('HTML Code: ' + server, {
                    title: text,
                    toaster: 'b-toaster-bottom-right',
                    variant: variant,
                    solid: true
                })
            },
            copy: function () {
                var htmlCode = document.querySelector('#out').value;
                navigator.clipboard.writeText(htmlCode)
                    .then(() => {
                        this.makeToast('success', 'Html code copied to clipboard', htmlCode.substr(0, htmlCode.length / 4) + '...');
                    })
                    .catch(e => {
                        this.makeToast('danger', e + 'Sorry, unable to copy to clipboard.');
                    });
            },
        },
    }
</script>

<style scoped>
    /deep/
    a {
        color: #343a40;
        font-weight: bold;
        text-decoration: none;
        background-color: transparent;
    }

    /deep/
    a:hover {
        color: #555a5f;
    }

    p a {
        display: block;
        color: white !important;
        font-weight: bold;
        text-align: center;
        padding-left: 16px;
        padding-right: 16px;
        text-decoration: none;
    }

</style>
