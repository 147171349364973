<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="offset-md-1 col-md-10">
                    <div class="card m-t-50">
                        <div class="card-header text-center">
                            Terms
                        </div>
                        <div class="card-body">
                            <h5>1. Terms</h5>
                            <p>By accessing this Website, accessible from https://hytale-servers.top,
                                you are agreeing to be bound by these Website Terms and Conditions of
                                Use and agree that you are responsible for the agreement with any
                                applicable local laws. If you disagree with any of these terms, you are
                                prohibited from accessing this site. The materials contained in this
                                Website are protected by copyright and trade mark law.</p>


                            <h5>2. Use License</h5>
                            <p>Permission is granted to temporarily download one copy of the materials
                                on {{ VUE_APP_WEB_TITLE }} Website for personal, non-commercial transitory viewing
                                only. This is the grant of a license, not a transfer of title, and under
                                this license you may not:</p><br>
                            <ul class="ui-checklist text-dark">
                                <li class="toggle">Modify or copy the materials.</li>
                                <li>Use the materials for any commercial purpose or for any public
                                    display.
                                </li>
                                <li>Attempt to reverse engineer any software contained on {{ VUE_APP_WEB_TITLE }}
                                    Website.
                                </li>
                                <li>Remove any copyright or other proprietary notations from the
                                    materials.
                                </li>
                                <li>Transferring the materials to another person or "mirror" the
                                    materials on any other server.
                                </li>
                            </ul>
                            <br>
                            <p>
                                This will let {{ VUE_APP_WEB_TITLE }} to terminate upon violations of any of these
                                restrictions. Upon termination, your viewing right will also be
                                terminated and you should destroy any downloaded materials in your
                                possession whether it is printed or electronic format.
                            </p>


                            <h5>3. Disclaimer</h5>
                            <p>All the materials on {{ VUE_APP_WEB_TITLE }} Website are provided "as is".
                                {{ VUE_APP_WEB_TITLE }} makes no warranties, may it be expressed or implied,
                                therefore negates all other warranties. Furthermore, {{ VUE_APP_WEB_TITLE }} does not
                                make any representations concerning the accuracy or reliability of the
                                use of the materials on its Website or otherwise relating to such
                                materials or any sites linked to this Website.</p>


                            <h5>4. Limitations</h5>
                            <p>{{ VUE_APP_WEB_TITLE }} or its suppliers will not be hold accountable for any damages
                                that will arise with the use or inability to use the materials on
                                {{ VUE_APP_WEB_TITLE }} Website, even if {{ VUE_APP_WEB_TITLE }} or an authorize representative
                                of this Website has been notified, orally or written, of the possibility
                                of such damage. Some jurisdiction does not allow limitations on implied
                                warranties or limitations of liability for incidental damages, these
                                limitations may not apply to you.</p>


                            <h5>5. Commissions</h5>
                            <p>{{ VUE_APP_WEB_TITLE }} offers a totally free service, for this reason, in order to
                                maintain the platform we can take a small commission of the apps that show
                                Admob Interstitial ads, the percentage of the commission is only 30%,
                                this commission does not apply to the Admob Banner or Admob Video Reward.
                                This percentage is taken automatically so you should not worry about
                                anything, otherwise if you don't use this type of ads in your applications
                                you will not have any type of commission.</p>


                            <h5>6. Revisions and Errata</h5>
                            <p>The materials appearing on {{ VUE_APP_WEB_TITLE }} Website may include technical,
                                typographical, or photographic errors. {{ VUE_APP_WEB_TITLE }} will not promise that
                                any of the materials in this Website are accurate, complete, or current.
                                {{ VUE_APP_WEB_TITLE }} may change the materials contained on its Website at any time
                                without notice. {{ VUE_APP_WEB_TITLE }} does not make any commitment to update the
                                materials.</p>


                            <h5>7. Links</h5>
                            <p>{{ VUE_APP_WEB_TITLE }} has not reviewed all of the sites linked to its Website and is
                                not responsible for the contents of any such linked site. The presence
                                of any link does not imply endorsement by {{ VUE_APP_WEB_TITLE }} of the site. The
                                use of any linked website is at the user’s own risk.</p>


                            <h5>8. Site Terms of Use Modifications</h5>
                            <p>{{ VUE_APP_WEB_TITLE }} may revise these Terms of Use for its Website at any time
                                without prior notice. By using this Website, you are agreeing to be
                                bound by the current version of these Terms and Conditions of Use.</p>


                            <h5>9. Governing Law</h5>
                            <p>Any claim related to {{ VUE_APP_WEB_TITLE }} Website shall be governed by the laws
                                of es without regards to its conflict of law provisions.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'terms',
        data() {
            return {
                VUE_APP_WEB_TITLE: process.env.VUE_APP_WEB_TITLE,
            };
        },
    }
</script>

<style scoped>
    body {
        background: #E5DDFF;
    }

    .m-t-50 {
        margin-top: 50px;
    }

    .card-body {
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        padding: 1em !important;
    }

    .card-header {
        background-color: #7a5fba !important;
        color: rgba(240, 255, 252, 0.9);
        font-size: 1.6em;
        font-weight: bold;

    }
</style>
