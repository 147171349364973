<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="offset-md-1 col-md-10">
                    <div class="card m-t-50">
                        <div class="card-header text-center">
                            Privacity
                        </div>
                        <div class="card-body">
                            <h4>Privacy Policy for {{ VUE_APP_WEB_TITLE }}</h4>
                            <p>
                                At {{ VUE_APP_WEB_TITLE }}, accessible from https://hytale-servers.top, one of our main
                                priorities is the privacy of our visitors. This Privacy Policy document contains
                                types of information that is collected and recorded by {{ VUE_APP_WEB_TITLE }} and how we use it.
                            </p>
                            <p> If you have additional questions or require more information about our Privacy
                                Policy, do not hesitate to contact us through email at</p>
                            <h5>Log Files</h5>
                            <p>{{ VUE_APP_WEB_TITLE }} follows a standard procedure of using log files. These files
                                log visitors when they visit websites. All hosting companies do this and
                                a part of hosting services' analytics. The information collected by log
                                files include internet protocol (IP) addresses, browser type, Internet
                                Service Provider (ISP), date and time stamp, referring/exit pages, and
                                possibly the number of clicks. These are not linked to any information
                                that is personally identifiable. The purpose of the information is for
                                analyzing trends, administering the site, tracking users' movement on
                                the website, and gathering demographic information.</p>

                            <h5>Cookies and Web Beacons</h5>
                            <p>Like any other website, {{ VUE_APP_WEB_TITLE }} uses 'cookies'. These cookies are used
                                to store information including visitors' preferences, and the pages on
                                the website that the visitor accessed or visited. The information is
                                used to optimize the users' experience by customizing our web page
                                content based on visitors' browser type and/or other information.</p>

                            <h5>Google DoubleClick DART Cookie</h5>
                            <p>Google is one of a third-party vendor on our site. It also uses cookies,
                                known as DART cookies, to serve ads to our site visitors based upon
                                their visit to www.website.com and other sites on the internet. However,
                                visitors may choose to decline the use of DART cookies by visiting the
                                Google ad and content network Privacy Policy at the following URL
                                <a href="https://policies.google.com/technologies/ads" target="_blank">
                                    Google</a>.</p>

                            <h5>Our Advertising Partners</h5>
                            <p>Some of advertisers on our site may use cookies and web beacons. Our
                                advertising partners are listed below. Each of our advertising partners
                                has their own Privacy Policy for their policies on user data. For easier
                                access, we hyperlinked to their Privacy Policies below <a
                                        href="https://policies.google.com/technologies/ads" target="_blank">Google</a>.
                            </p>

                            <h5>Privacy Policies</h5>
                            <p>You may consult this list to find the Privacy Policy for each of the
                                advertising partners of {{ VUE_APP_WEB_TITLE }}.</p>
                            <p>Third-party ad servers or ad networks uses technologies like cookies,
                                JavaScript, or Web Beacons that are used in their respective
                                advertisements and links that appear on {{ VUE_APP_WEB_TITLE }}, which are sent
                                directly to users' browser. They automatically receive your IP address
                                when this occurs. These technologies are used to measure the
                                effectiveness of their advertising campaigns and/or to personalize the
                                advertising content that you see on websites that you visit.</p>
                            <p>Note that {{ VUE_APP_WEB_TITLE }} has no access to or control over these cookies that
                                are used by third-party advertisers.</p>

                            <h5>Third Party Privacy Policies</h5>
                            <p>{{ VUE_APP_WEB_TITLE }} Privacy Policy does not apply to other advertisers or
                                websites. Thus, we are advising you to consult the respective
                                Privacy Policies of these third-party ad servers for more detailed
                                information. It may include their practices and instructions about
                                how to opt-out of certain options. You may find a complete list of
                                these Privacy Policies and their links here: Privacy Policy Links.</p>
                            <p>You can choose to disable cookies through your individual browser
                                options. To know more detailed information about cookie management
                                with specific web browsers, it can be found at the browsers'
                                respective websites. What Are Cookies?</p>

                            <h5>Children's Information</h5>
                            <p>Another part of our priority is adding protection for children while
                                using the internet. We encourage parents and guardians to observe,
                                participate in, and/or monitor and guide their online activity.</p>
                            <p> {{ VUE_APP_WEB_TITLE }} does not knowingly collect any Personal Identifiable
                                Information from children under the age of 13. If you think that your
                                child provided this kind of information on our website, we strongly
                                encourage you to contact us immediately and we will do our best efforts
                                to promptly remove such information from our records.</p>

                            <h5>Online Privacy Policy Only</h5>
                            <p>This privacy policy applies only to our online activities and is valid
                                for visitors to our website with regards to the information that they
                                shared and/or collect in {{ VUE_APP_WEB_TITLE }}. This policy is not applicable to
                                any information collected offline or via channels other than this
                                website.</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'privacity',
        data() {
            return {
                VUE_APP_WEB_TITLE: process.env.VUE_APP_WEB_TITLE,
            };
        },
    }
</script>

<style scoped>
    body {
        background: #E5DDFF;
    }

    .m-t-50 {
        margin-top: 50px;
    }

    .card-body {
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        padding: 1em !important;
    }

    .card-header {
        background-color: #7a5fba !important;
        color: rgba(240, 255, 252, 0.9);
        font-size: 1.6em;
        font-weight: bold;

    }
</style>
