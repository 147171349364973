<script>
    import {ApiService} from "../../ApiService";
    import {Bar, mixins} from 'vue-chartjs';

    const apiService = new ApiService();

    export default {
        extends: Bar,
        mixins: [mixins.reactiveData],
        props: ['server', 'period'],
        name: 'StatsByPlayers',
        data() {
            return {
                apiService: apiService,
                options: this.createOptions(this.period),
            }
        },
        mounted() {
            this.getServerStats();
            this.gradient = this.$refs.canvas.getContext('2d').createLinearGradient(0, 0, 0, 450)
            this.gradient2 = this.$refs.canvas.getContext('2d').createLinearGradient(0, 0, 0, 450)


            this.gradient.addColorStop(0, 'rgba(76,178,76, 0.9)')
            this.gradient.addColorStop(0.5, 'rgba(76,178,76, 0.5)');
            this.gradient.addColorStop(1, 'rgba(76,178,76, 0.2)');

            this.gradient2.addColorStop(0, 'rgba(241,59,59, 0.9)')
            this.gradient2.addColorStop(0.5, 'rgba(241,59,59, 0.25)');
            this.gradient2.addColorStop(1, 'rgba(241,59,59, 0)');
        },
        methods: {
            createOptions(period) {
              return {
                  responsive: true,
                  maintainAspectRatio: false,
                  layout: {
                      padding: {
                          left: 10,
                          right: 10,
                          top: 10,
                          bottom: 10
                      }
                  },
                  tooltips: {
                      mode: 'index',
                      axis: 'x',
                      intersect: false,
                      callbacks: {
                          label: (item) => ((item.datasetIndex === 0) ? 'Up: ' : 'Down: ') + item.value + '%'
                      }
                  },
                  scales: {
                      xAxes: [{
                          display: true,
                          stacked: true,
                          type: 'time',
                          offset: true,
                          time: {
                              parser: 'YYYY-MM-DD HH:00:00',
                              unit: (period === 'monthly') ? 'month' : (period === 'daily') ? 'day' : 'day',
                              stepSize: (period === 'monthly') ? 1 : (period === 'daily') ? 1 : 1,
                              tooltipFormat: (period === 'monthly') ? 'MM/YYYY' : (period === 'daily') ? 'DD/MM/YYYY' : 'DD/MM/YYYY',
                              displayFormats: {
                                  'day': 'DD/MM',
                                  'month': 'MM/YY'
                              }
                          },
                          scaleLabel: {
                              display: true,
                              labelString: (period === 'monthly') ? 'Months' : (period === 'daily') ? 'Days' : 'Days'
                          },
                      }],
                      yAxes: [{
                          display: true,
                          stacked: true,
                          scaleLabel: {
                              display: true,
                              labelString: 'Uptime'
                          },
                          ticks: {
                              max: 100,
                              min: 0,
                              stepSize: 25,
                              callback: function (value) {
                                  return value + '%';
                              }
                          },
                      }]
                  }
              };  
            },
            getServerStats() {
                apiService.getServerStats(this.server, 'uptime', this.period)
                    .then(response => {
                        this.chartData = {
                            labels: response.data.map(s => s.datetime),
                            datasets: [
                                {
                                    borderColor: '#006728',
                                    pointBackgroundColor: 'white',
                                    borderWidth: 1,
                                    backgroundColor: this.gradient,
                                    label: 'Up',
                                    data: response.data.map(s => (+s.up * 100 / (+s.up + +s.down)).toFixed(2)),
                                },
                                {
                                    label: 'Down',
                                    borderColor: '#c2223f',
                                    borderWidth: 1,
                                    backgroundColor: this.gradient2,
                                    data: response.data.map(s => (+s.down * 100 / (+s.up + +s.down)).toFixed(2)),
                                },
                            ],
                        };
                    })
            },
        },
        watch: {
            period: function (newVal) {
                this.$data._chart.destroy();
                this.chartData = [];
                this.getServerStats();
                this.options = this.createOptions(newVal);
                this.renderChart(this.chartData, this.options);
            },
        }
    }
</script>
