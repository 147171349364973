<template>
  <div class="col-lg-6 col-md-6 col-sm-12 mt-5 flex-grow-1">
    <div class="text-center font-weight-bold">
      <h3 class="text-dark font-weight-bold mb-5 text-uppercase"> Vote for {{ server.name }} </h3>
      <img class="adaptar-img mb-3" alt="server-banner" v-bind:src="server.banner">
      <h6 class="text-dark mb-5 text-left font-weight-lighter p-3">
        <pre id="description" >{{ server.description }}</pre>
      </h6>
      <b-alert class="text-center mb-5 ml-3 mr-3" show>You can vote every 24
        hours!
      </b-alert>
      <div>
        <b-button variant="secondary" @click="vote(server.uuid)" class="mb-5 p-2 pl-5 pr-5 btn btn-lg">
          <i class="fas fa-thumbs-up mr-3"></i>
          Vote it
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>

import {BAlert, BButton} from "bootstrap-vue";
import {ApiService} from "@/components/ApiService";

const apiService = new ApiService();

export default {
  name: 'ServerContent',
  props: ['server'],
  components: {
    BButton,
    BAlert,
  },
  methods: {
    async vote(uuid) {
      await this.$recaptchaLoaded();
      let token = await this.$recaptcha('vote');
      apiService.voteServer(uuid, token).then(response => {
        switch (response.data.status) {
          case 200:
          case 400:
            this.$dialog.alert(response.data.message);
            break;
          default:
            this.$dialog.alert('Oops, an error has occurred. Please try again.');
            break;
        }
      }).catch(() => {
        this.$dialog.alert('Oops, an error has occurred. Please try again.');
      })
    },
  }
}
</script>

<style scoped>
  .adaptar-img {
    width: 80%;
    min-height: 2em;
  }

  #description {
    white-space: pre-wrap;
    word-wrap: break-word;
    font-family: inherit;
  }
</style>
