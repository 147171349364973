<template>
    <router-link :to="{name: 'server', params: {uuid: server.uuid}}" :style="{ cursor: 'pointer'}" tag="tr">
        <td v-if="sponsored"><i class="fas fa-star"></i></td>
        <td class="p-2" v-else><p>#{{ position }}</p></td>
        <td class="pr-2 p-1">
            <table class="table text-center table-bordered table-borderless table-sm table-hover text-dark bg-light m-1"
                   style="border: 2px solid #dee2e6;">
                <tbody>
                <tr><h6 class="mt-2 p-1 text-uppercase" style="font-size:14px;">
                    {{ server.name }}&nbsp;</h6></tr>
                <tr><img alt="server-banner" class="resize-img" v-bind:src="server.banner"></tr>
                <tr>
                    <div class="d-flex justify-content-around p-2" style="font-size:14px;">
                        <h6 class="m-1">{{ server.address }}</h6>
                        <b-button v-if="supportsCB" @click.prevent="copy(server.address)" size="sm"
                                  class="border-0 shadow-none custom-button">
                            <i class="fas fa-copy fa-sm ml-2"></i>
                        </b-button>
                    </div>
                </tr>
                </tbody>
            </table>
        </td>
    </router-link>
</template>

<script>
    import {ApiService} from "./ApiService";
    import {BButton} from "bootstrap-vue"

    const apiService = new ApiService();

    export default {
        name: 'ServerTR',
        props: ['server', 'position', 'url', 'sponsored'],
        components: {BButton},
        computed: {
            status: () => {
                return Boolean(Math.round(Math.random()));
            }
        },
        data() {
            return {
                supportsCB: false,
                message: '',
                apiService: apiService,
            }
        },
        created() {
            if (navigator.clipboard) {
                this.supportsCB = true;
            }
        },
        methods: {

            makeToast(variant = null, text, server) {
                this.$bvToast.toast('IP: ' + server, {
                    title: text,
                    toaster: 'b-toaster-bottom-right',
                    variant: variant,
                    solid: true
                })
            },
            copy: function (serverAddress) {
                navigator.clipboard.writeText(serverAddress)
                    .then(() => {
                        this.makeToast('success', 'Code copied to clipboard', serverAddress);
                    })
                    .catch(e => {
                        this.makeToast('danger', e);
                        this.message = 'Sorry, unable to copy to clipboard.'
                    });
            }
        }
    }
</script>

<style scoped>

    .custom-button, .custom-button:hover, .custom-button:focus, .custom-button:active {
        color: #6c757d !important;
        background-color: transparent !important;
    }

    .resize-img {
        width: 100%;
        max-height: 4em;
        height: 4em;
        border: 2px solid #555a5f;
    }

    p a {
        display: block;
        color: white !important;
        text-align: center;
        padding-left: 16px;
        padding-right: 16px;
        text-decoration: none;
    }

    .table thead th {
        vertical-align: middle;
        border-bottom: 2px solid #dee2e6;
        font-size: 20px;
    }

    .table td, .table th {
        padding: .55rem;
        vertical-align: middle;
        border-top: 1px solid #dee2e6;
        text-align: center;
    }

</style>
