'use strict';

import axios from 'axios';

const SERVER = process.env.VUE_APP_SERVER;
const ENDPOINT = process.env.VUE_APP_SERVER_ENDPOINT;

export class ApiService {
    constructor() {
    }

    getApiUrl() {
        return SERVER + ENDPOINT;
    }

    getServers(type, sponsored, page = 1, order = 'votes') {
        return axios.get(this.getApiUrl() + '/servers', {
            params: {
                type: type,
                sponsored: sponsored,
                page: page,
                order: order,
            },
        });
    }

    getServer(uuid) {
        return axios.get(this.getApiUrl() + '/servers/' + uuid);
    }

    voteServer(uuid, token) {
        return axios.post(this.getApiUrl() + '/servers/' + uuid + '/vote', {
            'g-recaptcha-response': token,
        });
    }

    getServerStats(uuid, type = 'players', period = 'daily') {
        return axios.get(this.getApiUrl() + '/servers/' + uuid + '/stats', {
            params: {
                type: type,
                period: period
            },
        });
    }

    // getServerBannerSrc(uuid, type = 'sunset') {
    //     let url = new URL(this.getApiUrl() + '/servers/' + uuid + '/banner');
    //     url.searchParams.append('type', type);
    //     return url
    // }
}
