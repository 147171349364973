<template>
    <div>
        <div class="">
            <div class="col-10 ml-2 mt-2">
                <h3 class="text-uppercase text-dark text-left">{{ title }}</h3>
                <hr class="mb-0">
            </div>
            <div class="card-body">
                <table class="font-weight-bold table table-bordered table-borderless text-dark d-none d-md-table">
                    <thead class="thead-light">
                    <tr class="header-tr text-center text-uppercase">
                        <th scope="col"># Rank</th>
                        <th class="text-left" scope="col"><i class="fas fa-server fa-sm mr-2"></i>Server</th>
                        <th scope="col"><i class="fas fa-users fa-sm mr-2"></i>Players</th>
                        <th scope="col"><i class="fas fa-signal fa-sm mr-2"></i>Status</th>
                    </tr>
                    </thead>
                    <tbody v-if="servers === null">
                    <tr>
                        <td colspan="5">
                            <br>
                            <BeatLoader/>
                        </td>
                    </tr>
                    </tbody>
                    <tbody v-else-if="servers.length > 0">
                    <ServerTR
                            v-for="server in servers" :key="server.uuid"
                            v-bind:position="calculateServerRank(server)"
                            v-bind:server="server"
                            v-bind:sponsored="sponsored === 'true'"/>
                    </tbody>
                    <tbody v-else>
                    <tr>
                        <td colspan="5" class="no-servers">
                            <b>No sponsored servers to show</b>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <table class="font-weight-bold table table-bordered table-borderless text-dark d-table d-md-none">
                    <thead class="thead-light">
                    <tr class="header-tr text-center text-uppercase">
                        <th scope="col">#</th>
                        <th class="text-left" scope="col">Server</th>
                    </tr>
                    </thead>
                    <tbody v-if="servers === null">
                    <tr>
                        <td colspan="2">
                            <br>
                            <BeatLoader/>
                        </td>
                    </tr>
                    </tbody>
                    <tbody v-else-if="servers.length > 0">
                    <ServerTRSmall v-for="server in servers" :key="server.uuid"
                                   v-bind:position="calculateServerRank(server)"
                                   v-bind:server="server"
                                   v-bind:sponsored="sponsored === 'true'"/>
                    </tbody>
                    <tbody v-else>
                    <tr>
                        <td colspan="2">
                            <br>
                            <b>No sponsored servers to show</b>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <b-pagination
                v-if="sponsored === 'false'"
                v-model="current_page"
                :per-page="items_per_page"
                :total-rows="total_item_count"
                aria-controls="my-table"
                align="center"
                class="mt-5"
                @change="getServers"
                pills>
        </b-pagination>
    </div>
</template>

<script>
    import ServerTR from "./ServerTR";
    import ServerTRSmall from "./ServerTRSmall";
    import {ApiService} from './ApiService';
    import BeatLoader from 'vue-spinner/src/BeatLoader.vue'
    import {BPagination} from "bootstrap-vue"

    const apiService = new ApiService();

    export default {
        name: 'ServersGroup',
        props: ['title', 'sponsored'],
        components: {
            ServerTR,
            ServerTRSmall,
            BeatLoader,
            BPagination
        },
        data() {
            return {
                current_page: 1,
                servers: null,
                items_per_page: 15,
                total_item_count: 0,
                apiService: apiService,
            }
        },
        mounted() {
            this.getServers();
        },
        methods: {
            calculateServerRank: function (server) {
                return this.servers.findIndex(x => x.uuid === server.uuid) + 1 + ((this.current_page - 1) * this.items_per_page);
            },
            getServers(page = 1) {
                this.servers = null;
                let order = this.$route.query.order;
                apiService.getServers(process.env.VUE_APP_WEB_GAME, this.sponsored, page, order)
                    .then(response => {
                        this.current_page = response.data.current_page;
                        this.servers = response.data.items;
                        this.items_per_page = response.data.items_per_page;
                        this.total_item_count = response.data.total_item_count;
                    })
            },
        },
        watch: {
            $route() {
                this.servers = null;
                this.getServers();
            }
        }
    }
</script>

<style scoped>

    .header-tr th {padding: 10px !important;font-size: 16px;}

    /deep/
    .page-item.active .page-link { background-color: #454d55 !important;}

    .no-servers {padding: 30px !important;}

    th {
        font-size: 1.2em;
        padding-top: 25px !important;
        padding-bottom: 25px !important;
    }

    p a {
        display: block;
        color: white !important;
        font-weight: bold;
        text-align: center;
        padding-left: 16px;
        padding-right: 16px;
        text-decoration: none;
    }

    /deep/
    .b-pagination-pills .page-item .page-link {
        border-radius: 4px;
        margin: .3rem;
        min-width: 34px;
        -webkit-transition: .3s cubic-bezier(0, 0, .2, 1);
        transition: .3s cubic-bezier(0, 0, .2, 1);
        color: #454d55 !important;
        -webkit-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
        box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
    }

    /deep/
    .page-item.active .page-link {color: #ffffff !important; border-color: #454d55 !important}

</style>
