<template>
    <v-btn v-on:click="scrollToTop()" class =" custom-fab fab v-btn--bottom v-btn--contained v-btn--fab v-btn--fixed v-btn--right v-btn--round theme--dark v-size--large "
           style="bottom: 50px; transform-origin: center center; outline: 0 !important; background-color: #343a40">
            <i aria-hidden="true"
               class="v-icon notranslate mdi mdi-chevron-up theme--dark"></i>
    </v-btn>
</template>
<script>
    export default {
        name: 'Fab',
        data() {
            return {};
        },
        methods: {
            scrollToTop() {
                window.scrollTo(0,0);
            }
    }}
</script>