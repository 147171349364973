import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home';
import Server from "./views/Server";
import Terms from "./views/Terms";
import Privacity from "./views/Privacity";

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/servers/:uuid',
      name: 'server',
      props: true,
      component: Server
    },
    {
      path: '/terms',
      name: 'terms',
      component: Terms
    },
    {
      path: '/privacity',
      name: 'privacity',
      component: Privacity
    },
    {
      path: '*',
      redirect: "/",
    }
  ]
})
