<template>
    <div>
        <div class="custom-container">
            <div class="row text-center">
                <div class="col-md-12">
                    <ServersGroup title="Sponsored Servers" sponsored="true"/>
                </div>
                <br>
                <div class="col-md-12">
                    <ServersGroup title="Server List" sponsored="false"/>
                </div>
            </div>
        </div>
        <Fab></Fab>
    </div>
</template>

<script>
    import ServersGroup from "../components/ServersGroup";
    import Fab from "../components/Fab";

    export default {
        name: 'home',
        components: {
            ServersGroup,
            Fab
        },
    }
</script>

<style scoped>
    body {
        background: #E5DDFF;
    }

    .custom-container {
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    }

    @media (min-width: 576px) {
        .custom-container {
            max-width: 540px;
        }
    }

    @media (min-width: 768px) {
        .custom-container {
            max-width: 720px;
        }
    }

    @media (min-width: 992px) {
        .custom-container {
            max-width: 960px;
        }
    }

    @media (min-width: 1200px) {
        .custom-container {
            width: 1100px;
        }
    }
</style>
