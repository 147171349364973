<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <b-navbar toggleable="lg" type="dark" variant="dark" class="bd-navbar">
            <b-navbar-brand :to="{ name:'home' }" class="title"><i class="fas fa-gamepad space-icons"></i>&nbsp;{{
                VUE_APP_WEB_TITLE }}
            </b-navbar-brand>
            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
            <b-collapse id="nav-collapse" is-nav>
                <!-- Left -->
<!--                <b-navbar-nav>-->
<!--                    <b-nav-item class="m-md-2" href="#"><i class="fas fa-blog space-icons"></i>&nbsp;Blog</b-nav-item>-->
<!--                    &lt;!&ndash; Navbar dropdowns &ndash;&gt;-->
<!--                    <b-nav-item-dropdown toggle-class="text-light" class="m-md-2">-->
<!--                        <template v-slot:button-content><i class="fas fa-server space-icons"></i>&nbsp;Servers-->
<!--                        </template>-->
<!--                        <b-dropdown-item :to="{ name: 'home', query: {'order': 'new'}}">New Servers</b-dropdown-item>-->
<!--                        <b-dropdown-divider></b-dropdown-divider>-->
<!--                        <b-dropdown-item :to="{ name: 'home', query: {'order': 'updated'}}">Recently Updated-->
<!--                        </b-dropdown-item>-->
<!--                        <b-dropdown-divider></b-dropdown-divider>-->
<!--                        <b-dropdown-item :to="{ name: 'home', query: {'order': 'players'}}">Most Played-->
<!--                        </b-dropdown-item>-->
<!--                        &lt;!&ndash;<b-dropdown-item :to="{ name: 'home', query: {'order': 'new'}}">Random Server</b-dropdown-item>&ndash;&gt;-->
<!--                    </b-nav-item-dropdown>-->

<!--                    <b-nav-item-dropdown toggle-class="text-light" class="m-md-2">-->
<!--                        <template v-slot:button-content><i class="fas fa-plus space-icons"></i>&nbsp;More-->
<!--                            <strong>INFO</strong></template>-->
<!--                        <b-dropdown-item href="#"><i class="fas fa-user space-icons"></i>&nbsp;About us-->
<!--                        </b-dropdown-item>-->
<!--                        <b-dropdown-divider></b-dropdown-divider>-->
<!--                        <b-dropdown-item href="#"><i class="fas fa-question space-icons"></i>&nbsp;FAQ</b-dropdown-item>-->
<!--                        <b-dropdown-divider></b-dropdown-divider>-->
<!--                        <b-dropdown-item href="#"><i class="fas fa-gamepad space-icons"></i>&nbsp;Hytale-->
<!--                        </b-dropdown-item>-->
<!--                    </b-nav-item-dropdown>-->
<!--                </b-navbar-nav>-->

                <!-- Right -->
                <b-navbar-nav class="ml-auto">
                    <!--                    <b-nav-form>-->
                    <!--                        <b-form-input size="md" placeholder="Type a name, category..."></b-form-input>-->
                    <!--                        <b-button size="md" type="submit"><i class="fas fa-search"></i>&nbsp;Search</b-button>-->
                    <!--                    </b-nav-form>-->
                    <b-nav-item :href="VUE_APP_SERVER" target="_blank" class="custom-link">
                        <b-button size="md" type="button" class="bg"><i class="fas fa-home"></i>&nbsp;Dashboard
                        </b-button>
                    </b-nav-item>
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>
    </div>
</template>

<script>
    import {ApiService} from "./ApiService";
    import {
        BNavbar,
        BNavbarBrand,
        // BNavbarToggle,
        // BNavItem,
        // BButton,
        // BNavbarNav,
        // BNavItemDropdown,
        // BCollapse,
        // BDropdownDivider,
        // BDropdownItem
    } from "bootstrap-vue"

    const apiService = new ApiService();

    export default {
        name: 'Header',
        components: {
            BNavbar,
            BNavbarBrand,
            // BNavbarToggle,
            // BNavItem,
            // BNavItemDropdown,
            // BNavbarNav,
            // BButton,
            // BCollapse,
            // BDropdownDivider,
            // BDropdownItem
        },
        data() {
            return {
                VUE_APP_WEB_TITLE: process.env.VUE_APP_WEB_TITLE,
                VUE_APP_SERVER: process.env.VUE_APP_SERVER,
                apiService: apiService,
            }
        },
    }
</script>

<style scoped>

    .custom-link a:focus, .custom-link a:hover {
        color: #6c757d;
        background-color: transparent;
        outline: 0;
    }

    .bd-navbar {
        box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, .25), inset 0 -1px 5px rgba(0, 0, 0, .25);
        padding-left: 30px;
        background-color: #212529;
    }

    .navbar-dark .navbar-nav .nav-link {
        color: white !important;
    }

    .space-icons {
        margin-right: 10px;
    }

    .title {
        font-size: 30px;
        margin: 0 40px 0px 10px;
    }

    .bg {
        background-color: #454d55 !important
    }

    /*deep selector*/
    /deep/ .dropdown-item:hover,
    /deep/ .dropdown-item:focus,
    /deep/ .nav-link:hover,
    /deep/ .nav-link:focus {
        color: #ffffff !important;
        text-decoration: none;
        background-color: #454d55;
    }

    /deep/
    .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
    }

    @media screen and (max-width: 480px) {
        .title {
            font-size: 20px !important;
            margin: 0;
        }
    }
</style>
