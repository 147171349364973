<script>
    import {ApiService} from "../../ApiService";
    import {Line, mixins} from 'vue-chartjs';

    const apiService = new ApiService();

    export default {
        extends: Line,
        mixins: [mixins.reactiveData],
        props: ['server', 'period'],
        name: 'StatsByPlayers',
        data() {
            return {
                apiService: apiService,
                options: this.createOptions(this.period, 0),
            }
        },
        mounted() {
            this.getServerStats();
            this.gradient = this.$refs.canvas.getContext('2d').createLinearGradient(0, 0, 0, 450)

            this.gradient.addColorStop(0, 'rgba(76,178,76, 0.9)')
            this.gradient.addColorStop(0.5, 'rgba(76,178,76, 0.5)');
            this.gradient.addColorStop(1, 'rgba(76,178,76, 0.2)');
        },
        beforeDestroy() {
            this.$data._chart.destroy();
        },
        methods: {
            createOptions(period, maxClients) {
                return {
                    responsive: true,
                    maintainAspectRatio: false,
                    layout: {
                        padding: {
                            left: 10,
                            right: 10,
                            top: 10,
                            bottom: 10
                        }
                    },
                    legend: {
                        display: false
                    },
                    tooltips: {
                        mode: 'index',
                        axis: 'x',
                        intersect: false
                    },
                    scales: {
                        xAxes: [{
                            display: true,
                            type: 'time',
                            time: {
                                parser: 'YYYY-MM-DD HH:00:00',
                                unit: (period === 'monthly') ? 'month' : (period === 'daily') ? 'day' : (period === 'hourly') ? 'hour' : 'day',
                                stepSize: (period === 'monthly') ? 1 : (period === 'daily') ? 1 : (period === 'hourly') ? 6 : 1,
                                tooltipFormat: (period === 'monthly') ? 'MM/YYYY' : (period === 'daily') ? 'DD/MM/YYYY' : (period === 'hourly') ? 'HH:00 DD/MM/YYYY' : 'DD/MM/YYYY',
                                displayFormats: {
                                    'hour': 'HH:00 DD/MM',
                                    'day': 'DD/MM',
                                    'month': 'MM/YY'
                                },
                            },
                            scaleLabel: {
                                display: true,
                                labelString: (period === 'monthly') ? 'Months' : (period === 'daily') ? 'Days' : (period === 'hourly') ? 'Hours' : 'Days'
                            },
                        }],
                        yAxes: [{
                            display: true,
                            scaleLabel: {
                                display: true,
                                labelString: 'Players'
                            },
                            ticks: {
                                max: maxClients,
                                min: 0,
                            },
                        }]
                    }
                };
            },
            getServerStats() {
                apiService.getServerStats(this.server, 'players', this.period)
                    .then(response => {
                        //Store data
                        this.chartData = {
                            labels: response.data.map(s => s.datetime),
                            datasets: [
                                {
                                    label: 'Players',
                                    borderColor: '#555a5f',
                                    pointBackgroundColor: 'white',
                                    pointBorderColor: 'white',
                                    borderWidth: 1,
                                    backgroundColor: this.gradient,
                                    data: response.data.map(s => s.clients),
                                },
                            ],
                        };

                        //Render chart
                        let maxClients = Math.max.apply(Math, response.data.map(s => s.maxClients));
                        this.options = this.createOptions(this.period, maxClients);
                        this.renderChart(this.chartData, this.options);
                    })
            },
        },
        watch: {
            period: function () {
                this.$data._chart.destroy();
                this.getServerStats();
            }
        }
    }
</script>