<template>
    <footer class="footer mt-4" style="backgroundColor:#dee2e6">
        <div style="backgroundColor: #212529">
            <div class="container">
                <div class="row py-3 d-flex justify-content-between">
                    <div class="col-md-6 col-lg-5 text-center mb-4 mb-md-0">
                        <h6 class="mb-0 mt-2 text-light">Get connected with us on social networks!</h6>
                    </div>
                    <div class="col-md-6 col-lg-5 text-center social-icons-hover-effect social-icons">
                        <ul>
                            <li><a><i class="fab fa-facebook white-text"> </i></a></li>
                            <li> <a><i class="fab fa-twitter white-text"> </i></a></li>
                            <li><a><i class="fab fa-google-plus white-text"> </i></a></li>
                            <li><a><i class="fab fa-linkedin-in white-text"> </i></a></li>
                            <li><a><i class="fab fa-instagram white-text"> </i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="container text-center text-md-left mt-4">
            <div class="row mt-3">
                <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-2">
                    <h6 class="text-uppercase font-weight-bold">{{ VUE_APP_WEB_TITLE }} ({{ VUE_APP_VERSION }})</h6>
                    <hr class="separator mt-0 d-inline-block mx-auto">
                    <p>&copy; {{ new Date().getFullYear() }} | Not affiliated with <strong>{{ VUE_APP_WEB_GAME.toUpperCase() }}</strong></p>
                </div>
                <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-3">
                    <h6 class="text-uppercase font-weight-bold">Useful Links</h6>
                    <hr class="separator mt-0 d-inline-block mx-auto">
                    <p><a href="#!">Privacity</a></p>
                    <p><a href="#!">Terms</a></p>
                    <p><a href="#!">Info</a></p>
                </div>
                <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-3">
                    <h6 class="text-uppercase font-weight-bold">Contact</h6>
                    <hr class="separator mt-0 d-inline-block mx-auto">
                    <p><i class="fas fa-envelope mr-3"></i>hello@mytopservers.com</p>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
    export default {
        name: 'Footer',
        data() {
            return {
                VUE_APP_WEB_TITLE: process.env.VUE_APP_WEB_TITLE,
                VUE_APP_WEB_GAME: process.env.VUE_APP_WEB_GAME,
                VUE_APP_VERSION: process.env.VUE_APP_VERSION,
            };
        },
    }
</script>

<style scoped>
    p a {color: #212529;}

    ul{
        margin: 0 !important;
        padding: 0 !important;
    }

    .social-icons a {
        color: white;
        display: block;
        font-weight: bold;
        text-align: center;
        padding-left: 16px;
        padding-right: 16px;
        text-decoration: none;
    }

    .social-icons a:hover {
        color: #454d55;
    }

    .social-icons-hover-effect ul li {
        display: inline-block;
    }

    .social-icons-hover-effect ul li a {
        font-size: 25px;
        display: inline-block;
        -webkit-transition: all .6s ease;
        -moz-transition: all .6s ease;
        -ms-transition: all .6s ease;
        -o-transition: all .6s ease;
        transition: all .6s ease;
    }

    .social-icons-hover-effect ul li:hover a {
        border-radius: 50%;
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
    .separator{
        width:60px;
        background-color:#dee2e6;
    }
</style>
