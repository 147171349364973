<template>
  <b-card class="card-table">
    <b-container>
      <b-row>
        <b-col cols="3" class="vertical-border">
          <b-row>
            <b-col cols="12">
              <b>Votes</b>
            </b-col>
            <b-col cols="12">
              <b>Website</b>
            </b-col>
            <b-col cols="12">
              <b>Address</b>
            </b-col>
            <b-col cols="12">
              <b>Country</b>
            </b-col>
            <b-col cols="12">
              <b>Version</b>
            </b-col>
            <b-col cols="12">
              <b>Tags</b>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="9">
          <b-row>
            <b-col cols="12">
              {{ server.votes }}
            </b-col>
            <b-col cols="12">
              <b><a target="_blank" :href="server.website">{{ serverUrlHost }}</a></b>
            </b-col>
            <b-col cols="12">
              {{ server.address }}
            </b-col>
            <b-col cols="12">
              {{ server.votes }}
              <flag class="pr-1" :iso="server.country"/>
            </b-col>
            <b-col cols="12">
              {{ server.version.name }}
            </b-col>
            <b-col cols="12">
              <b-badge variant="dark" class="mr-2 mb-2" style="font-size: 100%" v-for="name in server.tags" :key="name.name">
                {{ name.name }}
              </b-badge>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </b-card>
</template>

<script>
import {BCard} from "bootstrap-vue";

export default {
  name: 'InfoTab',
  props: ['server'],
  components: {
    // BBadge,
    BCard,
  },
  computed: {
    serverUrlHost: function () {
      if(!this.server.website){
        return;
      }

      return new URL(this.server.website).host
    }
  }
}
</script>

<style scoped>

b {
  font-size: .9rem;
}
.card-body {
  padding: 0 !important;
}

.col-12:not(:last-child) {
  border-bottom: 1px solid #dee2e6;
}

.vertical-border {
  border-right: 1px solid #dee2e6;
}
</style>
